<template>
    <div style="width:100vw;margin:-12px;">
        <top-nav title="Crear Órdenes ATH Movil"></top-nav>

        <v-text-field v-model="customerQuery" class="rounded-xl mb-3 mx-3 mt-5" flat solo background-color="grey lighten-3" 
            label="Buscar" hide-details dense prepend-inner-icon="mdi-magnify" clearable></v-text-field>
        <div v-if="customerQuery && filteredCustomers.length === 0" class="d-flex justify-center align-center" style="width: 100%;line-break:anywhere;">
            <v-icon class="mr-2">mdi-alert-circle-outline</v-icon>
            <span>No se encontraron resultados para <strong>"{{ customerQuery }}"</strong></span>
        </div>
        <div v-else-if="customerQuery" class="d-flex justify-center align-center" style="width: 100%;line-break:anywhere;">
            <span>Mostrando {{ filteredCustomers.length }} {{ resultText }} para <strong>"{{ customerQuery }}"</strong></span>
        </div>

        <v-list two-line>
            <template v-for="customer in filteredCustomers">
                <v-list-item :key="customer.id" @click="getTransactions(customer)">
                    <v-list-item-avatar>
                        <v-img v-if="customer.profilePic != null && customer.profilePic.key" :src="customer.img"></v-img>
                        <v-avatar color="accent" size="36" class="white--text" v-else>{{ getCustomerInitials(customer) }}</v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="text-left">{{ getCustomerFullName(customer) }}</v-list-item-title>
                        <v-list-item-subtitle>{{ customer.email }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>

        <v-dialog v-model="transactionsDialog" persistent fullscreen>
            <v-card tile>
                <v-card-title class="justify-space-between">
                    <h2 class="petu-dark-green">Transacciones</h2>
                    <v-btn icon @click="transactionsDialog = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-subtitle>Seleccione una transacción para crear orden</v-card-subtitle>
                <v-card-text >
                    <v-list three-line class="pt-0">
                        <template v-for="(t, i) in transactions">
                            <v-list-item :key="`transaction-${i}`" class="px-0">
                                <v-list-item-content>
                                    <v-list-item-title class="d-flex align-end justify-space-between">
                                        <span>{{ t.referenceNumber }}</span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>{{ t.email }}</v-list-item-subtitle>
                                    <v-list-item-subtitle>Fecha: {{ (new Date(t.date)).toLocaleDateString() }} @ {{ (new Date(t.date)).toLocaleTimeString() }}</v-list-item-subtitle>
                                    <v-list-item-subtitle v-if="t.subtotal">Subtotal: ${{ t.subtotal.toLocaleString('es-PR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</v-list-item-subtitle>
                                    <v-list-item-subtitle>Total: ${{ t.total }}</v-list-item-subtitle>
                                    <v-list-item-subtitle v-if="t.items.length > 0">
                                        <span>Items:</span>
                                        <div v-for="(product, i) in t.products" :key="`product-${i}`" class="pl-3">
                                            {{ product.name }} x {{ t.items[i].quantity }}
                                        </div>
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        <v-btn @click="createOrder(t)" small depressed dark rounded class="petu-green-bg mt-5">Crear orden</v-btn>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider v-if="i < transactions.length - 1" :key="`divider-${i}`"></v-divider>
                        </template>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="isLoading" hide-overlay persistent content-class="rounded-lg" width="300">
            <v-card class="petu-green-bg pt-3" dark>
                <v-card-text> 
                    <span v-if="loading">Cargando datos</span>
                    <span v-else>Creando órden</span>
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { _st } from '@/softech';
import { API } from '@/inc/api';
import { mapGetters, mapActions } from 'vuex'
import { OrderStatus } from '@/models'
import TopNav from '@/components/admin/TopNav'

import { PetuProduct } from '@/classes/product'
import { PetuCustomer } from '@/classes/customer'
import { PetuOrder } from '@/classes/order'
import { PetuSubscription } from '@/classes/subscription'

import moment from 'moment'
import { SubscriptionFrequency } from '@/models'

export default {
    components: { TopNav },
    data: () => ({
        loading             : false,
        creatingOrder       : false,
        customer            : null,
        order               : null,
        subscription        : null,

        transactions        : [],

        transactionsDialog  : false,
        customerQuery       : '',
    }),
    async mounted() {
        await this.getCustomers()
    },
    computed: {
        ...mapGetters({ 
            customers: 'customersInfo/customers' 
        }),
        isLoading: ({ loading, creatingOrder }) => loading || creatingOrder,
        resultText: (filteredCustomers) => filteredCustomers.length === 1 ? 'resultado' : 'resultados',
        filteredCustomers() {
            return !this.customerQuery?.trim() ? this.customers : this.customers.filter(this.search)
        },
    },
    methods: {
        ...mapActions({ 
            getCustomers: 'customersInfo/getCustomers' 
        }),
        getCustomerInitials: (c) => `${c.firstName[0]}${c.lastName[0]}`,
        getCustomerFullName: (c) => `${c.firstName} ${c.lastName}`,
        search(c) {
            let q = this.customerQuery?.toLowerCase().trim() || null
            return this.getCustomerFullName(c).toLowerCase().includes(q) || c.email.toLowerCase().includes(q)
        },
        async getProducts(items) {
            let products = [];
            for (let i of items) {
                let p = new PetuProduct();
                await p.loadByName(i.name);
                products.push(p.data);
            }

            return products;
        },
        async getTransactions(customer) {
            this.customer = customer
            // usar variables de .env para los tokens
            // let axiosConfig = { headers: { 'Content-Type': 'application/json' } }
            let postData = {
                publicToken: "d8924b78d863e6fd917a9350ef6a63666cad1d74",
                email: customer.email,
                phone: _st.toPhoneNumber( customer.phone )
            }

            this.loading = true
            // descomentar en prod
            // axios.post('https://www.athmovil.com/rs/v3/searchTransaction', postData, axiosConfig).then((resp) => {
            //     this.transactions = resp.data
            //     this.transactionsDialog = true
            //     console.log(resp.data)
            // }).catch((e) => console.log(e))
            // .finally(() => this.loading = false)

            try {
                let api = new API();
                let res = await api.get(`/customer/${customer.id}/athmovil-transactions`);
                // this.transactions = await API.post('petuRestApi', '/athmovil-transactions', {
                //     body: postData
                // });

                if( res.data.status !== true ) {
                    console.log( res.data.message );
                    return;
                }

                this.transactions = res.data.data;

                if( !_st.isNUE( this.transactions.errorCode ) )
                    throw this.transactions.description;

                for( let i = 0; i < this.transactions.length; i++ ) {
                    this.transactions[i].products = await this.getProducts(this.transactions[i].items);
                }

                // eliminar en prod
                this.loading = false;
                this.transactionsDialog = true;
            }
            catch(error) {
                this.loading = false;
                console.log(error);
            }
        },
        async createOrder(transaction) {
            this.creatingOrder = true
            try {
                const products = transaction.products.map((p, i) => {
                    return { 
                        productId     : p.id, 
                        qty           : transaction.items[i].quantity,
                        price         : transaction.items[i].price,
                        tax           : transaction.items[i].price * 0.115,
                        total         : Number(transaction.items[i].price) + (transaction.items[i].price * 0.115),
                        isTaxable     : true,
                        name          : p.name,
                        type          : p.type,
                        trainingType  : p.trainingType,
                        length        : 0, 
                        width         : 0, 
                        height        : 0,
                        weight        : 0,
                        images        : p.images
                    }
                })

                let order = new PetuOrder()
                order.data = {
                    ...order.data,
                    status: OrderStatus.PROCESSING.toLowerCase(),
                    customerId: this.customer.id,
                    products,
                    subtotal: transaction.subtotal,
                    discounts: 0.00,
                    shipping: 0.00,
                    tax: transaction.tax,
                    total: transaction.total,
                    coupons: [],
                    paymentMethod: 'athmovil',
                    paymentConfirmation: transaction.referenceNumber,
                    fromSubscriptionID: '',
                }

                // TODO: ***** ME QUEDE AQUI, GRABANDO LA SUSCRIPCION *****
                await order.save()
                // this.createSubscriptions(transaction, order)
                this.$root.$emit('alert', { status: 'success', message: `Órden ha sido creada` })
            } 
            catch(err) {
                console.error(err)
                this.$root.$emit('alert', { status: 'error', message: `Error al crear la órden` })
            } 
            finally {
                this.creatingOrder = false
            }
        },
        async createSubscriptions(transaction, order) {
            try {
                let customer = new PetuCustomer()
                await customer.load(this.customer.id)

                let subscriptions = await customer.getSubscriptions()
                for (let p of transaction.products) {
                    if(!p.generateSubscription) continue

                    let found = subscriptions.find((s) => s.productID === p.id)
                    let subscription = null
                    if(_st.isNUE(found)) {
                        subscription = new PetuSubscription()
                        subscription.data.autoRenew = false
                        subscription.data.productId = p.id
                        subscription.data.customerId = customer.data.id
                        subscription.data.remainingSessions = p.subscriptionSessions
                        subscription.data.orderId = order.data.id
                    } else {
                        subscription = new PetuSubscription(found)
                        subscription.data.remainingSessions += p.subscriptionSessions
                        subscription.data.orderId = order.data.id
                        subscription.data.lastRenewal = moment()
                    }

                    if (p.subscriptionFrequency === SubscriptionFrequency.WEEKLY.toLowerCase()) {
                        subscription.data.nextPaymentDate = moment(order.data.orderDate).add(1, 'w')
                    } else if(p.subscriptionFrequency === SubscriptionFrequency.ANNUALLY.toLowerCase()) {
                        subscription.data.nextPaymentDate = moment(order.data.orderDate).add(1, 'y')
                    } else {
                        subscription.data.nextPaymentDate = moment(order.data.orderDate).add(1, 'M')
                    }

                    await subscription.save();
                    this.$root.$emit('alert', { status: 'success', message: `Suscripción ha sido creada` })
                }
            } catch(err) {
                console.error(err)
                this.$root.$emit('alert', { status: 'error', message: `Error al crear suscripción` })
            }
        }
    },
}
</script>